<template>
  <div class="main-wrapper">
    <div class="container">
      <h1>{{ $t('headline') }}</h1>

      <button class="languageSwitch" @click="toggleLanguage">{{ currentLocale === 'de' ? 'Switch to English' : 'Wechseln zu Deutsch' }}</button>

      <div class="wheels-container">
        <div class="wheel">
          <FortuneWheel style="width: 80vw; max-width: 100%; margin-bottom: 1rem;" :duration="1000" @rotateEnd="onRotateEnd" :prizes="prizesNumbers" :canvas="canvasOptions" />
        </div>
      </div>
      <div class="legend" v-for="(legend, index) in legends" :key="index">
          <div>{{ legend }}</div>
      </div>

    </div>

    <footer>
      <a href="https://mykinkgames.com">Home</a>
      <a href="https://mykinkgames.com/imprint">{{ $t('imprint') }}</a>
    </footer>
</div>
</template>

<style>
.main-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
}


h1 {
  font-family: 'Saira Extra Condensed', sans-serif;
  color: #8aab00;
  font-size: 2em;
  text-align: center;  
  margin-bottom: 1rem;
}

.container {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  padding: 20px;
}

.wheels-container {
  margin-top: 3em;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.wheel {
  display: flex;
  justify-content: center;
} 

#app FortuneWheel {
  font-family: 'Saira Extra Condensed', sans-serif;
  font-weight: 800;
}

.fw-container[data-v-5d19212d] {
  position: relative;
  display: inline-block;
  font-size: 0;
  overflow: hidden
}

.fw-container canvas[data-v-5d19212d],
.fw-container img[data-v-5d19212d] {
  display: block;
  width: 100%
}

.fw-btn[data-v-5d19212d] {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%
}

.fw-btn__btn[data-v-5d19212d] {
  position: relative;
  width: 100%;
  height: 100%;
  background: #fff;
  border: 6px solid #fff;
  border-radius: 50%;
  /* color: rgb(138,171,0); */
  color: white;
  text-align: center;
  font-size: 52px;
  font-weight: 800;
  font-family: 'Saira Extra Condensed', sans-serif;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none
}

.fw-btn__btn[data-v-5d19212d]:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 18px solid transparent;
  border-right: 18px solid transparent;
  border-bottom: 22px #fff solid;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%)
}

.fw-btn__btn[data-v-5d19212d]:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 18px #15BD96 solid;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%) translateY(6px);
  z-index: 10
}

.fw-btn__image[data-v-5d19212d] {
  display: inline-block
}

.fw-btn__btn[data-v-5d19212d] {
  background: radial-gradient(circle, #8aab00, #8aab00);
  border: 6px solid violet;
  border-radius: 50%;
  box-shadow:
    inset 0 -2px 4px rgba(0, 0, 0, 0.1),
    inset 0 2px 4px rgba(255, 255, 255, 0.3);
  transition: all 0.3s ease;
}

.fw-btn__btn[data-v-5d19212d]:active {
  box-shadow:
    inset 0 -1px 2px rgba(0, 0, 0, 0.1),
    inset 0 1px 2px rgba(255, 255, 255, 0.3);
}

.fw-btn__btn[data-v-5d19212d]:before {
  border-bottom-color: #8aab00;
}

.fw-btn__btn[data-v-5d19212d]:after {
  border-bottom-color: violet;
}
body{
  background-color: black;
}

@media (min-width: 768px) {
  .wheels-container{
    flex-direction: row;
  }

  h1 {
    font-size: 3em;
  }

  .wheel {
    width: 40vw;
    margin-left: 20px;
  }
}

footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
  background-color: #111;
  margin-top: 2rem;
}

footer a {
  color: white;
  font-family: 'Saira Extra Condensed', sans-serif;
  margin: 0 1rem;
  text-decoration: none;
  transition: color 0.3s;
}

footer a:hover {
  color: #8aab00;
}

.legend {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Saira Extra Condensed', sans-serif;
  color: #8aab00;
  font-size: 1.2em;
  margin-top: 2rem;
  padding-left: 2vw;
  padding-right: 2vw;
}

.languageSwitch {
  background-color: #8aab00;
  color: white;
  font-size: 1em;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

</style>

<script>
import FortuneWheel from 'vue-fortune-wheel'
import Swal from 'sweetalert2'
import '@sweetalert2/theme-dark/dark.css';

export default {
  components: {
    FortuneWheel
  },
  data() {
    return {
      currentLocale: 'de',  // Startsprache ist Deutsch
      prizesNumbers: [
        { id: 1, name: '1', value: '1', probability: 10, bgColor: '#A500AB', color: '#ffffff' },
        { id: 2, name: '2', value: '2', probability: 10, bgColor: '#751878', color: '#ffffff' },
        { id: 3, name: '3', value: '3', probability: 10, bgColor: '#A500AB', color: '#ffffff' },
        { id: 4, name: '4', value: '4', probability: 10, bgColor: '#751878', color: '#ffffff' },
        { id: 5, name: '5', value: '5', probability: 10, bgColor: '#A500AB', color: '#ffffff' },
        { id: 6, name: '6', value: '6', probability: 10, bgColor: '#751878', color: '#ffffff' },
        { id: 7, name: '7', value: '7', probability: 10, bgColor: '#A500AB', color: '#ffffff' },
        { id: 8, name: '8', value: '8', probability: 10, bgColor: '#751878', color: '#ffffff' },
        { id: 9, name: '9', value: '9', probability: 10, bgColor: '#A500AB', color: '#ffffff' },
        { id: 10, name: '10', value: '10', probability: 10, bgColor: '#751878', color: '#ffffff' }
      ],
      canvasOptions: {
        btnWidth: 120,
        borderColor: '#8aab00',
        borderWidth: 6,
        lineHeight: 30,
        textLength: 8,
        btnText: 'Go',
      },
    }
  },
  methods: {
    toggleLanguage() {
      this.currentLocale = this.currentLocale === 'de' ? 'en' : 'de';
      this.$i18n.locale = this.currentLocale;
    },
    onRotateEnd(prize) {
    let message = '';
    let status = null;

    if(prize.value >= 1 && prize.value <= 7) {
      message = `${this.$t('results.res1_a')} ${prize.value} ${this.$t('results.res1_b')}`;
      status = 'success';
    }
    if(prize.value == 8) {
      message = `${this.$t('results.res2')}`;
      status = 'failed';
    }
    if(prize.value == 9) {
      message = `${this.$t('results.res3')}`;
      status = 'failed';
    }
    if(prize.value == 10) {
      message = `${this.$t('results.res4')}`;
      status = 'failed';
    }

    //const title = this.$t(status === 'failed' ? 'errorTitle' : 'successTitle');
    Swal.fire({
      title: `${this.$t('result')}: ${prize.value}`,
      text: message,
      icon: status === 'failed' ? 'error' : 'success',
      confirmButtonText: this.$t('buttonText'),
      confirmButtonColor: status === 'failed' ? '#db2828' : '#8aab00',
    });
  }

},
computed: {
  legends() {
    return [
      this.$t('p1'),
      this.$t('p2'),
      this.$t('p3'),
      this.$t('p4'),
    ];
  }
},

}
</script>
