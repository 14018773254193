import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'

import App from './App.vue'

const i18n = createI18n({
  locale: 'de',
  fallbackLocale: 'en',
  messages: {
    en: {
      result: 'Result',
      headline: 'Silent Night',
      p1: '1-7 Tonight you will sleep for x hours with your hands tied without a cockcage',
      p2: '8 = LOSER! You stay locked up and sleep on the floor for 3 hours',
      p3: '9 = LOSER! You stay locked up and sleep on the floor for 5 hours',
      p4: '10 = LOSER! You stay locked up and sleep on the floor for the whole night',
      imprint: 'Imprint',
      results:{
        res1_a: 'Tonight you will sleep for ',
        res1_b: ' hours with your hands tied without a cockcage',
        res2: 'LOSER! You stay locked up and sleep on the floor for 3 hours',
        res3: 'LOSER! You stay locked up and sleep on the floor for 5 hours',
        res4: 'LOSER! You stay locked up and sleep on the floor for the whole night',
      },
      buttonText: 'Ok',
    },
    de: {
      result: 'Ergebnis',
      headline: 'Glückliche Nachtruhe',
      p1: '1-7 die jeweilige Zahl in Stunden Freiheit',
      p2: '8 = Loser, du schläfst 3 Std. auf dem Fußboden mit KG',
      p3: '9 = Loser, du schläfst 5 Std. auf dem Fußboden mit KG',
      p4: '10 = Loser, du schläfst die ganze Nacht auf dem Fußboden mit KG',
      imprint: 'Impressum',
      message: {
        sleep: 'Du schläfst x Stunden mit KG'
      },
      results:{
        res1_a: 'Du schläfst heute Nacht ',
        res1_b: ' mit gefesselten Händen ohne KG',
        res2: 'Loser! Du bleibst verschlossen und schläfst zusätzlich 3 Std. auf dem Fußboden',
        res3: 'Loser! Du bleibst verschlossen und schläfst zusätzlich 5 Std. auf dem Fußboden',
        res4: 'Loser! Du bleibst verschlossen und schläfst zusätzlich die ganze Nacht auf dem Fußboden',
      },
      buttonText: 'Ok',
    }
  }
})

createApp(App).use(i18n).mount('#app')


